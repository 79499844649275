import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible.js'
import 'element-ui/lib/theme-chalk/index.css'
import 'babel-polyfill'

import VCalendar from 'v-calendar'

/* element-ui所有组件
 */
import ElementUI from 'element-ui'

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc' // Use <vc-calendar />
})

Vue.config.productionTip = false

Vue.use(ElementUI)

// 判断pc或移动端使用
Vue.prototype.$equipment = function () {
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
