<template>
  <div>
  </div>
</template>

<script>
// import newslistweb from './web/newspagesweb/newslistweb.vue'
export default {
  name: 'Home',
  methods: {
    _isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  },
  mounted () {
    // console.log(document.documentElement.clientWidth)
    if (this._isMobile() || document.documentElement.clientWidth < 576) {
      this.$router.push({
        path: '/newslistweb'
      })
    } else {
      this.$router.push({
        path: '/index'
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>
