<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import URL from '../public/config'
export default {
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      dialogVisible: false,
      isfile: '',
      isRouterAlive: true
    }
  },
  methods: {
    // reRegister () {
    //   this.dialogVisible = false
    //   window.location.href = URL.URL + '/user/login'
    // },
    // reload () {
    //   this.isRouterAlive = false
    //   this.$nextTick(function () {
    //     this.isRouterAlive = true
    //   })
    // }
  },
  mounted () {
    // this.$router.replace('/pc_index');
    // this.isfile = localStorage.getItem('fail')
    // if (this.isfile === 'true') {
    //   this.dialogVisible = true
    // }
  }
}
</script>

<style lang="less">
.w{
  width: 1200px;
  margin: 0 auto;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
}
.cellBtn {
  color: #2B4F96;
  font-size: 14px;
  margin: 0 5px;
  cursor: pointer;
}
.infotitles{
  font-size:24px;
  // color: #3333;
  text-align: left;
  font-weight: 800;
  // position: relative;
  .linebottom{
    display: inline-block;
    // width: 48px;
    // height: 6px;
    border-bottom: 5px solid #409EFF;
    // background: #2B4F96;
    // position: absolute;
    // left: 0;
    // top: 22px;
  }
}
</style>
<style scoped lang='less'>
/deep/.ql-size-small{
  font-size: 14px;
}
/deep/.ql-size-large{
  font-size: 20px;
}
/deep/.ql-size-huge{
  font-size: 32px;
}
/deep/.ql-align-center{
  text-align: center;
}
/deep/.ql-align-right{
  text-align:right ;
}
/deep/.ql-align-justify{
  text-align:justify ;
}
  /deep/.ql-indent-1{
  text-indent:2em;
  }
  /deep/.ql-indent-2{
  text-indent:4em;
  }
  /deep/.ql-indent-3{
  text-indent:6em;
  }
  /deep/.ql-indent-4{
  text-indent:8em;
  }
</style>
