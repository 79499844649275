import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // pc端路由
  {
    // 门户首页
    path: '/index',
    name: 'index',
    component: () => import('../views/pc/homepage/index.vue')
  },
  {
    // 新闻列表页
    path: '/newslist',
    name: 'newslist',
    component: () => import('../views/pc/newspages/newslist.vue')
  },
  {
    // 新闻详情页
    path: '/newsdetails',
    name: 'newsdetails',
    component: () => import('../views/pc/newspages/newsdetails.vue')
  },
  // 移动端路由
  {
    // 移动新闻列表页
    path: '/newslistweb',
    name: 'newslistweb',
    component: () => import('../views/web/newspagesweb/newslistweb.vue')
  },
  {
    // 移动新闻详情页
    path: '/newsdetailsweb',
    name: 'newsdetailsweb',
    component: () => import('../views/web/newspagesweb/newsdetailsweb.vue')
  },
  // 宣讲会路由
  {
    // 宣讲会详情
    path: '/CareerTalkList',
    name: 'CareerTalkList',
    component: () => import('../views/CareerTalk/CareerTalkList.vue')
  },
  {
    // 宣讲会详情
    path: '/CareerTalkInfo',
    name: 'CareerTalkInfo',
    component: () => import('../views/CareerTalk/CareerTalkInfo.vue')
  },
  // 双选会路由
  {
    // 双选会详情
    path: '/mutualSelectionList',
    name: 'mutualSelectionList',
    component: () => import('../views/mutualSelection/mutualSelectionList.vue')
  },
  {
    // 双选会详情
    path: '/mutualSelectionInfo',
    name: 'mutualSelectionInfo',
    component: () => import('../views/mutualSelection/mutualSelectionInfo.vue')
  }
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
